(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name calendar.championship.controller:ChampionshipCalendarCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.public.calendar.championship')
    .controller('ChampionshipCalendarCtrl', ChampionshipCalendarCtrl);

  function ChampionshipCalendarCtrl($filter, championshipCalendar, championship, categories, sports, genders, towns) {
    var vm = this;
    vm.ctrlName = 'ChampionshipCalendarCtrl';

    vm.championship = championship;

    vm.buttons = {
      dial: true,
      buttonImage: 'images/ic_more_vert_white_48px.svg',
      tooltip: 'Accions',
      actions: [
        {
          changeState: false,
          execute: printReport,
          buttonImage: 'images/ic_print_black_24px.svg',
          tooltip: $filter('translate')('CALENDAR.PRINT')
        }
      ]
    };

    vm.category = $filter('translate')('CATEGORIES.' + findItemById(categories, vm.championship.categoryId).name);
    vm.sport = $filter('translate')('SPORTS.' + findItemById(sports, vm.championship.sportId).name);
    vm.gender = $filter('translate')(findItemById(genders, vm.championship.genderId).name);

    vm.teams = buildTeams(championshipCalendar.teams);
    vm.facilities = buildFacilities(championshipCalendar.facilities, towns);

    vm.matchDays = buildMatchDays(championshipCalendar.matchDays, vm.teams, vm.facilities, championshipCalendar.sportMatchDay);

    vm.isEmpty = function (collection) {
      return _.isEmpty(collection);
    };

    function printReport() {
      var championshipTpl = '<div flex>' +
        '<h1 class="md-title">' + $filter('translate')("CALENDAR.CHAMPIONSHIP") + '</h1>' +
        '<div layout="row" layout-align="space-around none" layout-padding layout-wrap>' +
        '<span class="md-title">' + $filter('translate')("CHAMPIONSHIPS.NAME") + ': </span>' +
        '<span>' + vm.championship.name + '</span><br/>' +
        '<span class="md-title">' + $filter('translate')("CATEGORY") + ': </span>' +
        '<span>' + vm.category + '</span><br/>' +
        '<span class="md-title">' + $filter('translate')("SPORT") + ': </span>' +
        '<span>' + vm.sport + '</span><br/>' +
        '<span class="md-title">' + $filter('translate')("GENDER") + ': </span>' +
        '<span>' + vm.gender + '</span><br/>' +
        '</div>' +
        '</div>';
      var matchesTpl = (document.getElementById('matches') !== null) ? document.getElementById('matches').innerHTML : '';
      var facilitiesTpl = (document.getElementById('facilities') !== null) ? document.getElementById('facilities').innerHTML : '';
      var teamsTpl = (document.getElementById('teams') !== null) ? document.getElementById('teams').innerHTML : '';
      var template = championshipTpl + matchesTpl + facilitiesTpl + teamsTpl;
      var printWin = window.open('', '',
        'left=0, top=0, width=' + screen.availWidth + ', height=' + screen.availHeight
        + ', toolbar=0, scrollbars=0, status=0');
      printWin.document.write('<html><body onload="window.print()"><style>' + getPrintStyles() + '</style>' + template + '</body></html>');
      printWin.document.close();
    }

    function getPrintStyles() {
      return 'body {-webkit-print-color-adjust: exact;}' +
        '@media print { .matchday-container { page-break-inside: avoid; } }' +
        '.match-container, .facility-container, .team-container {display: block;}' +
        '.match-header {padding: 3mm;}' +
        '.match-container span, .facility-container span, .team-container span {padding: 2mm 0;}' +
        '.match-container span:nth-child(1), .facility-container span:nth-child(1), .team-container span:nth-child(1) {padding-left: 2mm;}' +
        '.match-container span, .facility-container span, .team-container span {display: inline-block;}' +
        '.match-container span:nth-child(1) {width: 30mm;}' +
        '.match-container span:nth-child(2), .match-container span:nth-child(n+4) {width: 45mm;}' +
        '.match-container span:nth-child(3) {width: 10mm;}' +
        '.facility-container span:nth-child(1) {display: none;}' +
        '.facility-container span:nth-child(n+2) {width: 50mm;}' +
        '.facility-container span:nth-child(5) {width: 20mm;}' +
        '.team-container span {display: inline-block; width: 25mm;}';
    }


    vm.postponedMatch = postponedMatch;

    function postponedMatch(match) {
      return match.postponed;
    }

    vm.matchHasUnresolvedCancellation = matchHasUnresolvedCancellation;

    function matchHasUnresolvedCancellation(match) {
      if (angular.isDefined(match.lastCancellation) && !_.isNull(match.lastCancellation)) {
        if (match.lastCancellation.resolved === false) return true;
      }
      return false;
    }


    function buildTeams(teams) {
      var newTeams = {};
      angular.forEach(teams, function (team) {
        newTeams[team.teamInfo.id] = team;
      });
      return newTeams;
    }

    function buildFacilities(facilities, towns) {
      var newFacilities = {};
      angular.forEach(facilities, function (facility) {
        newFacilities[facility.id] = facility;
        newFacilities[facility.id].town = findItemById(towns, facility.idTown);
      });
      return newFacilities;
    }

    function buildMatches(matches, teams, facilities) {
      var newMatches = [];
      angular.forEach(matches, function (match) {
        newMatches.push({
          date: moment(match.date).format('L'),
          time: match.time,
          localScore: match.localScore,
          visitorScore: match.visitorScore,
          postponed: match.postponed,
          team1: teams[match.localTeamId],
          team2: teams[match.visitorTeamId],
          facility: facilities[match.facilityId],
          lastPostponement: match.lastPostponement,
          lastCancellation: match.lastCancellation
        });
      });
      return newMatches;
    }

    function buildMatchDays(matchDays, teams, facilities, sportMatchDay) {
      angular.forEach(matchDays, function (matchDay) {
        matchDay.matches = buildMatches(matchDay.matches, teams, facilities);
        matchDay.sportMatchDay = false;
      });
      matchDays = addSportMatchDay(matchDays, sportMatchDay);
      return matchDays;
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }

    function addSportMatchDay(matchDays, sportMatchDay) {
      matchDays = _.sortBy(matchDays, 'date');
      if (_.size(matchDays) && sportMatchDay > _.head(matchDays).date && sportMatchDay < _.last(matchDays).date) {
        matchDays.push({
          sportMatchDay: true,
          date: sportMatchDay
        });
      }
      return _.sortBy(matchDays, 'date');
    }
  }
}());
